// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/lisaweb.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "*[data-v-01266098] {\n  box-sizing: border-box;\n}\n.form-control[data-v-01266098],\n.btn[data-v-01266098] {\n  height: unset;\n}\n.login[data-v-01266098] {\n  width: 100%;\n  height: 100%;\n  max-width: 450px;\n  padding: 100px 20px;\n  margin: auto;\n  font-size: 16px;\n  line-height: 25px;\n}\nh3[data-v-01266098] {\n  font-weight: bold;\n}\nh5[data-v-01266098] {\n  font-weight: bold;\n}\n.footer[data-v-01266098] {\n  margin-top: 20%;\n}\n.cont[data-v-01266098] {\n  display: flex;\n  height: 100vh;\n  margin-left: 0 !important;\n  padding-left: 0 !important;\n}\n.bg-image[data-v-01266098] {\n  /* The image used */\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  /* Center and scale the image nicely */\n  background-repeat: no-repeat;\n  background-position: left top;\n  background-size: 100% 100%;\n  flex: 1;\n  height: 100%;\n}\n.left-logo[data-v-01266098] {\n  width: 500px;\n  max-width: 50%;\n  min-width: 25%;\n}\n.content[data-v-01266098] {\n  flex: 2;\n  height: 100%;\n  overflow: auto;\n}\n\n/* Fluid container API */\np[data-v-01266098] {\n  font-size: 1rem;\n  line-height: 1.5rem;\n}\n.fluid-container.api[data-v-01266098] {\n  background: #6f6259;\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n}\n.fluid-container.api > *[data-v-01266098]:last-child {\n  margin-bottom: 0px;\n  color: #fff;\n  font-size: 1rem;\n}", ""]);
// Exports
module.exports = exports;
